import React, { Suspense, lazy } from 'react'
import { Web3ReactProvider } from "@web3-react/core"
import { ethers } from 'ethers'

import LoadingPage from './pages/loading'
import ReactDOM from 'react-dom/client'

import './index.css'
import Loader from './data/loading/loader'

const App = lazy(() => import('./App'))


const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider)
  library.pollInterval = 12000

  return library
}


Number.prototype.countDecimals = function () {
  if(Math.floor(this.valueOf()) === this.valueOf()) return 0;
  return this.toString().split(".")[1].length || 0; 
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Suspense fallback={<LoadingPage/>}>
        <Loader loadedContent={<App />} />
      </Suspense>
    </Web3ReactProvider>
  </React.StrictMode>
)
