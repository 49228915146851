import styles from './loading.module.css'
import BSwapLogo from '../images/boda/BSwapLogo.png'

export const LoadingPage = () => {
    return (
        <div className={styles.container}>
            <img src={BSwapLogo} alt='BODA Logo' />
            <h1 className={styles.fancyText}>Loading...</h1>
        </div>
    )
}

export default LoadingPage
