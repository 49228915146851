import React, { useEffect, useState } from "react"
import LoadingPage from "../../pages/loading"

import BSwapLogo from '../../images/boda/BSwapLogo.png'
import BodaSwap from '../../images/boda/BodaSwap.png'
import BodaSwapSvg from '../../images/boda/BodaSwap.svg'

import ARBLogo from '../../images/chains/ARB.svg'
import BinanceChain from '../../images/chains/BinanceChain.png'
import CRO from '../../images/chains/CRO.svg'
import ETH from '../../images/chains/ETH.svg'
import MATIC from '../../images/chains/MATIC.svg'
import PLS from '../../images/chains/PLS.png'

import Anation from '../../images/tokens/devel/Anation.png'
import Apollo from '../../images/tokens/devel/Apollo_256.png'
import BNB from '../../images/tokens/devel/BNB.png'
import BODAV2 from '../../images/tokens/devel/BODAV2.png'
import CAKE from '../../images/tokens/devel/CAKE.png'
import CPTLC from '../../images/tokens/devel/CPTLC.png'
import CROLogo from '../../images/tokens/devel/CRO.svg'
import ETHLogo from '../../images/tokens/devel/ETH.png'
import EverRevoke from '../../images/tokens/devel/EverRevoke.svg'
import MATICLogo from '../../images/tokens/devel/MATIC.svg'
import PLSLogo from '../../images/tokens/devel/PLS.png'
import SDX from '../../images/tokens/devel/SDX.png'
import USDC from '../../images/tokens/devel/USDC.svg'
import USDT from '../../images/tokens/devel/USDT.svg'

import MetaMask from '../../images/wallets/MetaMask.svg'
import GenericWallet from '../../images/wallets/GenericWallet.svg'
import TrustWallet from '../../images/wallets/TrustWallet.png'
import WalletConnect from '../../images/wallets/WalletConnect.svg'


type LoaderProps = {
    loadedContent: React.ReactNode
}
export default function Loader(props: LoaderProps) {
    const [isLoaded, setIsLoaded] = useState(false)

    const cacheResources = () => {
        const assets = [
            BSwapLogo, BodaSwap, BodaSwapSvg, ARBLogo, 
            BinanceChain, CRO, ETH, MATIC, PLS, Anation, Apollo, 
            BNB, BODAV2, CAKE, CPTLC, CROLogo, ETHLogo,
            EverRevoke, MATICLogo, PLSLogo, SDX, USDC, USDT,
            MetaMask, GenericWallet, TrustWallet, WalletConnect
        ]

        assets.map(x => new Image().src = x)
    }

    useEffect(() => {
        const loadResources = async () => {
            cacheResources()

            setIsLoaded(true)
        }

        loadResources()
    })

    return (
        <>
            { isLoaded ? props.loadedContent : <LoadingPage /> }
        </>
    )
}
